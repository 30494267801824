/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

div.highlight, pre.highlight {
    border-radius: 10px;
}

.highlighter-rouge {
    @apply dark:bg-slate-800
}
code.highlighter-rouge {
    @apply dark:bg-slate-700
}
